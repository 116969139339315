<template>
    <div>
      <div class="goods_page flex" v-if="keyType==1">
        <div class="item_block" v-for="(item, index) in goodsList" :key="index">
          <div class="sec_item" @click="navigateTo(`/shopDetail/shopDetail/${item.id}`)">
            <div class="sec_item_img">
              <img v-if="item.cover!=''&&item.cover!=null&&item.cover!='null'" :src="common.setImgLocation(item.cover)" alt=""/>
              <img v-else :src="item.user.avatar" alt=""/>
            </div>
            <div v-if="item.store!=''&&item.store!=null&&item.store!='null'" class="sec_item_title ellipsis-1">
            {{item.store}}
            </div>
            <div v-else class="sec_item_title ellipsis-1">
            {{item.user.nick_name}}
            </div>
            <!-- <div class="sec_item_title ellipsis-1">
            {{item.desc}}
            </div> -->
          </div>
        </div>
      </div>
      <div class="goods_page flex" v-if="keyType==2">
        <div class="item_block" v-for="(item, index) in goodsList" :key="index" @click="navigateTo(`/goodsDetail/goodsDetail/${item.id}`)">
          <div class="sec_item" >
            <div class="sec_item_img">
              <img :src="common.setImgLocation(item.images[0])" alt=""/>
            </div>
            <div class="sec_item_title ellipsis-1">
            {{item.name}}
            </div>
            <div class="sec_item_price"><span>￥</span>{{item.top_price}}</div>
            <div class="sec_item_remark">
              <div><i class="el-icon-box"></i>{{item.stock_num}}</div>
              <div><i class="el-icon-view"></i>{{item.views}}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px;" v-if="keyType==3">
       <div class="right">
        <div
          class="item flex align-items-center justify-content-between"
          v-for="(item, index) in goodsList"
          :key="index"
           @click="btnClick(item.type, item.id)"
        >
          <div class="i_left flex align-items-center justify-content-between">
            <!-- <div class="box_img">
              <img src="../../static/logo.png" alt="" />
            </div> -->
            <div class="box_timer">
              <div class="box_timer_1">{{(item.created_at).slice(0, 4)}}</div>
              <div class="box_timer_2">{{(item.created_at).slice(5, 10)}}</div>
            </div>
          </div>
          <div class="i_right flex align-items-center justify-content-between">
            <div class="content">
              <div class="title">{{ item.title }}</div>
              <div v-if="item.type == 1" class="desc">资讯</div>
              <div v-if="item.type == 2" class="desc">赛事</div>
              <div v-if="item.type == 3" class="desc">功虫录</div>
              <div v-if="item.type == 4" class="desc">产地市场</div>
            </div>
            <div><i class="el-icon-arrow-right"></i></div>
          </div>
        </div>
      </div>
      </div>
    </div>
</template>
<script>
export default {
  name: "goodsList",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    keyType: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  watch: {
    list: function () {
      this.goodsList = this.list;
    },
  },
  data() {
    return {
      goodsList: [],
      baseUrl: 'https://gzh.xs521.com/'
    };
  },
  methods: {
    btnClick(type, id) {
      if(type == 1) {
        this.navigateTo(`/articleDetail/articleDetail/${id}`)
      } else if(type == 2) {
        this.navigateTo(`/articleDetail/brticleDetail/${id}`)
      } else if(type == 3) {
        this.navigateTo(`/gclDetail/gclDetail/${id}`)
      } else if(type == 4) {
        this.navigateTo(`/articleDetail/crticleDetail/${id}`)
      }
    }
  },
  mounted() {
    // console.log(this.goodsList, "---this.goodsList");
  },
};
</script>
<style lang="scss" scoped>
.goods_page {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 0 0;
  width: 100%;
  .item_block {
    width: calc(100% / 5);
  }
  .sec_item {
    margin: 0 auto;
    width: 170px;
    margin-bottom: 15px;
    box-shadow: 0px 0px 5px #bdbbbb;
    cursor: pointer;
    .sec_item_img {
      width: 170px;
      height: 170px;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 5px;
      img {
        width: 100%;
      }
    }

    .sec_item_title {
      text-align: center;
      padding: 5px;
    }
    .sec_item_price {
      color: #ac3030;
      font-size: 18px;
      font-weight: 800;
      span {
        font-size: 14px;
        padding: 0 5px;
      }
    }
    .sec_item_remark {
      color: #99a9bf;
      font-size: 12px;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      i {
        padding-right: 5px;
      }
    }
  }
}
.right {
    width: 750px;
    margin: auto;
    .item {
      width: 100%;
      background-color: rgb(247, 247, 247);
      padding: 5px;
      margin-bottom: 20px;
      box-shadow: 0px 0px 5px #bdbbbb;
      cursor: pointer;
      .i_left {
        width: 230px;
        .box_img {
          width: 120px;
          height: 80px;
          img {
            height: 100%;
            overflow: hidden;
          }
        }
        .box_timer {
          width: 80px;
          text-align: center;
          .box_timer_1 {
            font-size: 22px;
          }
          .box_timer_2 {
            font-size: 16px;
            color: rgb(187, 187, 187);
          }
        }
      }
      .i_right {
        width: calc(100% - 230px);
        padding-right: 20px;
        .content {
          width: 660px;
          padding: 0 10px;
          border-left: 1px solid #ccc;
          .title {
            font-size: 16px;
            font-weight: 800;
          }
          .desc {
            padding-top: 5px;
          }
        }
      }
    }
  }
</style>