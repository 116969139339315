<template>
  <div class="search_page">
    <!-- 顶部导航 -->
    <div class="top_navigation">
      <div class="top_navi_box flex align-items-center justify-content-between">
        <div class="box_logo flex align-items-center" @click="shouyeClick">
          <img src="../../static/logo.png" alt="" /><span>中蟋网交易市场</span>
        </div>
        <div class="box_item flex align-items-center justify-content-around">
          <div class="b_item" @click="navigateTo(`/index/index`)">首页</div>
          <div class="b_item" @click="navigateTo(`/auctionShow/auctionShow`)">
            拍品
          </div>
          <div class="b_item" @click="navigateTo(`/news/news`)">资讯</div>
          <div class="b_item" @click="navigateTo(`/competition/competition`)">
            赛事
          </div>
          <div class="b_item" @click="navigateTo(`/gongchonglu/gongchonglu`)">
            功虫录
          </div>
          <div class="b_item" @click="navigateTo(`/farmMarket/farmMarket`)">
            产地市场
          </div>
          <div class="b_item" @click="navigateTo(`/salesRank/salesRank`)">
            销量排行
          </div>
          <div class="b_item" @click="goPage('http://zbbs.zxwpm.com')">
            交易贴吧
          </div>
          <!-- <div class="b_item">APP下载</div> -->
        </div>
        <div class="box_search flex">
          <input type="text" v-model="keyword" @keyup.enter="search()" />
          <div class="el_icon">
            <el-select v-model="value" placeholder="分类" class="sel" size="medium" style="">
              <el-option
                v-for="item in textList"
                :key="item.type"
                :label="item.text"
                :value="item.type"
                popper-class="input"
                @click.native="typeClick(item.type)">
              </el-option>
            </el-select>
          </div>
          <div class="btn_search" @click="search()">
            <i class="el-icon-search"></i>搜索
          </div>
        </div>
      </div>
    </div>
    <!-- 主体内容 -->
    <div class="container">
      <div class="container">
        <div class="top_title">
          <span>展示</span>
        </div>
        <div class="sec_l_content">
          <goodsList :list="list" :keyType="keyType"></goodsList>
           <el-empty v-if="list.length === 0" description="暂无商品"></el-empty>
        </div>
      </div>
      <div class="pagination" v-if="keyType==1">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="amount"
          :page-size="20"
          :current-page="page"
          @current-change="currentChange"
          @prev-click="prevPage"
          @next-click="nextPage"
        >
        </el-pagination>
      </div>
      <div class="pagination" v-if="keyType==2">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="amount"
          :page-size="20"
          :current-page="page"
          @current-change="currentChange"
          @prev-click="prevPage"
          @next-click="nextPage"
        >
        </el-pagination>
      </div>
      <div class="pagination" v-if="keyType==3">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="amount"
          :page-size="20"
          :current-page="page"
          @current-change="currentChange"
          @prev-click="prevPage"
          @next-click="nextPage"
        >
        </el-pagination>
      </div>
    </div>
     <!-- 页脚 -->
    <div class="footer">
      <!-- 服务 -->
      <div class="box_serves">
        <div class="box_serve_imgs">
          <img :src="common.setImgLocation('/pc/img/apk.b30b851f.png')" alt="" />
        </div>
        <!-- <div>安卓下载(浏览器扫码下载)</div> -->
      </div>
      <div
        class="box_serve"
        @click="navigateTo(`/customerService/customerService`)"
      >
        <div class="box_serve_imgs">
          <img src="../../static/serve.png" alt="" />
        </div>
        <div>联系客服</div>
      </div>
      <div class="footer_content">
        <div class="footer_left">
          <div style="display: flex;">
            <div v-for="(item, index) in linkList" :key="index">
              <div v-if="item.url == ''" style="margin-right: 20px; cursor:pointer;" @click="indexClick(item.custom_links)">{{item.name}}</div>
            </div>
          </div>
          <div style="display: flex;">
            <div v-for="(item, index) in linkList" :key="index">
              <img v-if="item.url != ''" style="width: 88px; height: 31px; cursor:pointer; margin: 15px 0;" @click="indexClick(item.custom_links)" :src="item.url" alt="">
            </div>
          </div>
          <div class="footer_left_title">济宁中蟋网文化传播有限公司</div>
          <div class="footer_left_text">
            地址：山东省济宁市兖州区颜店镇滋阳山国际农业文化产业园南300米路东
          </div>
          <div class="footer_left_text">电话：13854735110</div>
          <div class="footer_left_text">座机：05373790808</div>
          <div class="footer_left_text">邮箱：zxwadmin@126.com</div>
          <div class="footer_left_text">gzh.xs521.com：鲁ICP备2021037169号-1</div>
        </div>
        <div class="footer_middle">
          <div class="footer_middle_title">关于我们</div>
          <div class="footer_middle_text" @click="navigateTo(`/auctionShow/auctionShow`)">拍品管理</div>
          <div class="footer_middle_text" @click="navigateTo(`/news/news`)">资讯中心</div>
          <div class="footer_middle_text" @click="navigateTo(`/salesRank/salesRank`)">销量排行</div>
          <div class="footer_middle_text" @click="navigateTo(`/customerService/customerService`)">联系客服</div>
        </div>
        <div class="footer_right">
          <div class="footer_right_img">
            <!-- <img src="https://www.xs521.com/qrcode.png" alt="" /> -->
            <img src="../../static/public.png" alt="">
          </div>
          <div class="footer_right_text">扫一扫 关注公众号</div>
        </div>
        <div class="footer_right">
          <div class="footer_right_img">
            <img src="../../static/market.png" alt="" />
          </div>
          <div class="footer_right_text">扫一扫 进入交易市场</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import goodsList from "@/components/goodsLists";
export default {
  components: { goodsList },
  data() {
    return {
      linkList: [],
      keyword: "",
      list: [],
      amount: 0,
      current: 0,
      page: 1,
      textList: [
        {
          text: '商家',
          type: '1'
        },
        {
          text: '拍品',
          type: '2'
        },
        {
          text: '文章',
          type: '3'
        }
      ],
      value: '',
      keyType: ''
    };
  },
  methods: {
    indexClick(link) {
      window.open(link, '_blank');
    },
    getLink() {
       this.$http.customLink().then(res => {
           console.log(res, '数据')
          this.linkList = res.data.record;
       })
   },
    shouyeClick() {
       this.navigateTo(`/index/index`);
    },
    goPage(url) {
      console.log(url)
      window.location.href = url
    },
    typeClick(type) {
      this.keyType = type
      this.getData()
    },
    currentChange(e) {
      console.log(e)
      this.page = e;
      this.getData();
    },
    prevPage(e) {
      console.log(e)

      this.page = e;
      this.getData();
    },
    nextPage(e) {
      console.log(e)

      this.page = e;
      this.getData();
    },
    getData() {
      console.log(this.keyType)
      // 商家
      if(this.keyType == 1) {
        this.$http
        .searchShops(
          `&keyword=${this.keyword}` + `&type=${this.keyType}` + `&page=${this.page}` + `&size=${10}`
        )
        .then((res) => {
          this.list = res.data.shops;
          this.amount = res.data.count;
          console.log(res, "---res");
          console.log(res.data.shops);
        });
      } else if(this.keyType == 2) { //拍品
          this.$http
        .searchGoods(
          `&keyword=${this.keyword}` + `&type=${this.keyType}` + `&page=${this.page}` + `&size=${10}`
        )
        .then((res) => {
          this.amount = res.data.count;
          this.list = res.data.goods;
          console.log(res, "---res");
        });
      } else if(this.keyType == 3) { //文章
        this.$http
        .searchList(
          `&keyword=${this.keyword}` + `&type=${this.keyType}` + `&page=${this.page}` +`&title=${''}`
        )
        .then((res) => {
          this.amount = res.data.count;
          this.list = res.data.goods;
          console.log(res, "---res");
        });
      } else {
        this.$http
        .SearchKeyword(
          `&keyword=${this.keyword}` + `&type=${this.keyType}` + `&page=${this.page}` + `&size=${10}`
        )
        .then((res) => {
          this.amount = res.data.count;          
          this.list = res.data.goods;
          console.log(res, "---res");
          console.log(this.list.length);
        });
      }
    },
    search(text) {
      this.page = 1;
      if(text) this.keyword = text
      this.getData();
    },
  },
  mounted() {
    console.log(this.$route.params)
    this.keyword = this.$route.params.kwd=='undefined'?'':this.$route.params.kwd;
    this.keyType =this.$route.params.type;
    this.getData();
    this.getLink();
  },
};
</script>
<style lang="scss" scoped>
.search_page {
  min-height: 600px;
}
.top_navigation {
  background-color: #ac3030;
  width: 100%;
  color: #fff;

  .top_navi_box {
    width: 1250px;
    margin: 0px auto;
    height: 50px;
    .box_logo {
      font-size: 20px;
      span {
        font-weight: 600;
        padding-left: 10px;
        width: 160px;
      }
      img {
        width: 40px;
        height: 40px;
      }
    }
    .box_item {
      width: calc(100% - 500px);
      font-size: 16px;
      font-weight: 600;
      .b_item {
        padding: 6px 24px;
        cursor: pointer;
      }
      .b_item:hover {
        background-color: #921515c2;
      }
    }
    .box_search {
      input {
        background-color: #fff;
        width: 180px;
        height: 34px;
        margin-top: 1px;
        border-radius: 0px;
        border: none;
        color: #333;
        padding: 0 5px;
        font-size: 14px;
        border-radius: 5px 0 0 5px;
      }
      .el_icon {
        width: 80px;
        margin-left: -40px;
        .sel{
          position: absolute;
          clip: rect(1px 80px 35px 3px);
          width: 90px;
          height: 28px;
          line-height: 28px;
          font-size: 15px;
          font-weight: bold;
        }
      }
      .btn_search {
        text-align: center;
        line-height: 35px;
        height: 36px;
        width: 80px;
        background-color: #921515c2;
        color: #fff;
        cursor: pointer;
        border-radius: 0 5px 5px 0;
      }
      .btn_search:hover {
        background-color: #c23737;
      }
    }
  }
}
.container {
  width: 1226px;
  margin: 0 auto;
  min-height: 500px;
  .container {
    margin-top: 50px;
    width: 100%;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    .top_title {
      line-height: 50px;
      height: 50px;
      padding: 0 20px;
      font-size: 20px;
      font-weight: 800;
      border-bottom: 1px solid #c0c0c0;
    }
  }
  .pagination {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #c70707; //修改后的背景图颜色
      color: #fff;
    }
  }
}
.footer {
  width: 100%;
  // height: 266px;
  background-color: rgb(20, 20, 20);
  margin-top: 50px;

  .box_serve {
    position: fixed;
    width: 80px;
    text-align: center;
    font-weight: 800;
    bottom: 100px;
    right: 100px;
    cursor: pointer;
    .box_serve_imgs {
      width: 70px;
      height: 70px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .footer_content {
    width: 1226px;
    margin: 0px auto;
    color: #fff;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    .footer_left {
      .footer_left_title {
        font-size: 20px;
        padding-bottom: 20px;
        font-weight: 800;
      }
      .footer_left_text {
        font-size: 14px;
        // font-weight: 800;
      }
      .footer_left_text:nth-last-child(1) {
        padding-top: 20px;
      }
    }
    .footer_middle {
      .footer_middle_title {
        font-size: 16px;
        font-weight: 800;
        padding-bottom: 20px;
      }
      .footer_middle_text {
        padding-bottom: 10px;
        cursor: pointer;
      }
      .footer_middle_text:hover {
        text-decoration:underline;
      }
    }
    .footer_right {
      .footer_right_img {
        width: 140px;
        height: 140px;
        // background-color: pink;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .footer_right_text {
        text-align: center;
        padding: 20px 0;
        font-size: 14px;
      }
    }
  }
}
.decoratio {
  color: blue;
  text-decoration: none;
}

.box_serves {
    position: fixed;
    // width: 80px;
    text-align: center;
    font-weight: 800;
    top: 100px;
    right: 50px;
    cursor: pointer;
    .box_serve_imgs {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>